import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * @author pasted from codeslayer1's react-ckeditor-component
 * @description CKEditor component to render a CKEditor textarea with defined configs and all CKEditor events handler
 *
 */

class CKEditor extends Component {
  constructor(props) {
    super(props);
    this.onLoad = this.onLoad.bind(this);
  }
  componentDidMount() {
    this.onLoad();
  }

  componentDidUpdate() {
    const editor = this.editorInstance;
    if (editor && editor.getData() !== this.props.content) {
      editor.setData(this.props.content);
    }
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  static onCKEditorDialogDefintion() {
    window.CKEDITOR.on('dialogDefinition', ev => {
      // Take the dialog name and its definition from the event
      const dialogName = ev.data.name;

      const dialogDefinition = ev.data.definition;
      // vérifie qu'on est sur la popup des liens

      const infoTab = dialogDefinition.getContents('info');
      if (dialogName === 'link') {
        dialogDefinition.removeContents('target');
        infoTab.remove('emailBody');
        infoTab.remove('emailSubject');
        infoTab.remove('linkType');
        infoTab.remove('emailOptions');
        infoTab.remove('anchorOptions');
        infoTab.remove('telOptions');
        infoTab.get('protocol').default = 'https://';
      }
      if (dialogName === 'image') {
        const linkInfoTab = dialogDefinition.getContents('Link');
        linkInfoTab.remove('cmbTarget');
        linkInfoTab.remove('browse');
        const txtUrl = linkInfoTab.get('txtUrl');
        // txtUrl.default = 'https://';
        txtUrl.onChange = function onChange() {
          let c = this.getValue();
          if (c && !/^http:\/\/|https:\/\//g.test(c)) {
            c = `https://${c}`;
            this.setValue(c);
          }
        };
      }
    });
  }

  onLoad() {
    if (this.unmounting) return;

    if (!window.CKEDITOR) {
      // eslint-disable-next-line no-console
      console.error('CKEditor not found');
      return;
    }

    this.editorInstance = window.CKEDITOR.replace(this.props.name, this.props.config);

    if (this.props.content && !(this.props.events && this.props.events.instanceReady)) {
      this.editorInstance.on('instanceReady', () => {
        this.editorInstance.editable().setHtml(this.props.content);
      });
    }
    Object.keys(this.props.events).forEach(event => {
      this.editorInstance.on(event, this.props.events[event]);
    });
  }
  render() {
    return (
      <div id={this.props.name}>
        <div name={this.props.name} className={this.props.activeClass} />
      </div>
    );
  }
}
CKEditor.defaultProps = {
  content: '',
  config: {},
  activeClass: '',
  events: {},
};

CKEditor.propTypes = {
  content: PropTypes.any,
  config: PropTypes.object,
  activeClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  events: PropTypes.object,
};

export default CKEditor;

export const defaultCkeHtml = `<html dir="ltr" lang="fr"><head><title data-cke-title=""></title><style data-cke-temp="1">html{cursor:text;*cursor:auto}
img,input,textarea{cursor:default}</style><link type="text/css" rel="stylesheet" href="${process.env.REACT_APP_PUBLIC_URL}/ckeditor/contents.css?t=IA8F"><link type="text/css" rel="stylesheet" href="${process.env.REACT_APP_PUBLIC_URL}/ckeditor/plugins/tableselection/styles/tableselection.css"><style data-cke-temp="1">.cke_editable{cursor:text}.cke_editable img,.cke_editable input,.cke_editable textarea{cursor:default}
.cke_contents_ltr a.cke_anchor,.cke_contents_ltr a.cke_anchor_empty,.cke_editable.cke_contents_ltr a[name],.cke_editable.cke_contents_ltr a[data-cke-saved-name]{background:url(${process.env.REACT_APP_PUBLIC_URL}/ckeditor/plugins/link/images/anchor.png?t=IA8F) no-repeat left center;border:1px dotted #00f;background-size:16px;padding-left:18px;cursor:auto;}.cke_contents_ltr img.cke_anchor{background:url(${process.env.REACT_APP_PUBLIC_URL}/ckeditor/plugins/link/images/anchor.png?t=IA8F) no-repeat left center;border:1px dotted #00f;background-size:16px;width:16px;min-height:15px;height:1.15em;vertical-align:text-bottom;}.cke_contents_rtl a.cke_anchor,.cke_contents_rtl a.cke_anchor_empty,.cke_editable.cke_contents_rtl a[name],.cke_editable.cke_contents_rtl a[data-cke-saved-name]{background:url(${process.env.REACT_APP_PUBLIC_URL}/ckeditor/plugins/link/images/anchor.png?t=IA8F) no-repeat right center;border:1px dotted #00f;background-size:16px;padding-right:18px;cursor:auto;}.cke_contents_rtl img.cke_anchor{background:url(${process.env.REACT_APP_PUBLIC_URL}/ckeditor/plugins/link/images/anchor.png?t=IA8F) no-repeat right center;border:1px dotted #00f;background-size:16px;width:16px;min-height:15px;height:1.15em;vertical-align:text-bottom;}
.cke_widget_wrapper{position:relative;outline:none}.cke_widget_inline{display:inline-block}.cke_widget_wrapper:hover>.cke_widget_element{outline:2px solid #ffd25c;cursor:default}.cke_widget_wrapper:hover .cke_widget_editable{outline:2px solid #ffd25c}.cke_widget_wrapper.cke_widget_focused>.cke_widget_element,.cke_widget_wrapper .cke_widget_editable.cke_widget_editable_focused{outline:2px solid #47a4f5}.cke_widget_editable{cursor:text}.cke_widget_drag_handler_container{position:absolute;width:15px;height:0;display:none;opacity:0.75;transition:height 0s 0.2s;line-height:0}.cke_widget_wrapper:hover>.cke_widget_drag_handler_container{height:15px;transition:none}.cke_widget_drag_handler_container:hover{opacity:1}img.cke_widget_drag_handler{cursor:move;width:15px;height:15px;display:inline-block}.cke_widget_mask{position:absolute;top:0;left:0;width:100%;height:100%;display:block}.cke_editable.cke_widget_dragging, .cke_editable.cke_widget_dragging *{cursor:move !important})
.cke_upload_uploading img{opacity: 0.3}</style></head><body contenteditable="true" class="cke_editable cke_editable_themed cke_contents_ltr" spellcheck="false"></body></html>`;
